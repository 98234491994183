<template>
  <div>
    <AppLayout />
    <notifications group="notifications" position="bottom right" />
  </div>
</template>
<script>
import AppLayout from "@/components/AppLayout.vue";

export default {
  components: {
    AppLayout,
  },
};
</script>
<style src="./style.css" />
