import axios from "axios";

function create() {
  let axiosPro = axios.create({
    // baseURL: "http://ast-group/api/",
    baseURL: "/public/api/",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  return axiosPro;
}

export default create;
