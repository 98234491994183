<template>
  <v-card class="p-3">
    <h3>Ваши задачи</h3>
    <div></div>
    <h4>Сегодняшние</h4>
    <v-expansion-panels v-if="today.length" class="lighter">
      <v-expansion-panel v-for="task in today" :key="task.id">
        <v-expansion-panel-header>{{ task.name }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="task.text" />
          <span class="d-block"
            >Дата выполнения: <b>{{ task.date }}</b></span
          >
          <div class="row">
            <v-btn
              color="primary"
              class="q-mx-md"
              v-if="task.order_id"
              @click="$router.push('/order/' + task.order_id), $emit('close')"
            >
              К заказу
            </v-btn>
            <v-btn
              color="success"
              v-if="!task.confirmed"
              @click="acceptTask(task.id)"
              >Принять
            </v-btn>
            <v-btn
              color="success"
              v-if="task.confirmed"
              @click="doneTask(task.id)"
              >Указать выполненной
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <v-alert color="info" class="my-5">У вас нет задач на сегодня</v-alert>
    </div>
    <h4>Будущее</h4>
    <v-expansion-panels v-if="future.length" class="lighter">
      <v-expansion-panel v-for="task in future" :key="task.id">
        <v-expansion-panel-header>{{ task.name }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="task.text" />
          <span class="d-block"
            >Дата выполнения: <b>{{ task.date }}</b></span
          >
          <v-btn
            class="m-1"
            color="primary"
            v-if="task.order_id"
            @click="$router.push('/order/' + task.order_id), $emit('close')"
          >
            К заказу
          </v-btn>
          <v-btn
            class="m-1"
            color="success"
            v-if="!task.confirmed"
            @click="acceptTask(task.id)"
            >Принять
          </v-btn>
          <v-btn
            class="m-1"
            color="success"
            v-if="task.confirmed"
            @click="doneTask(task.id)"
            >Указать выполненной
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <v-alert color="info" class="my-5">У вас нет задач на будущее</v-alert>
    </div>
  </v-card>
</template>
<script>
import create from "@/axios";

let axiosPro = create();
export default {
  emits: ["getData", "close"],
  props: {
    today: {
      required: true,
    },
    future: {
      required: true,
    },
  },
  methods: {
    acceptTask(id) {
      this.$store.state.isLoading = true;
      axiosPro
        .post("/accept-task", { id })
        .then(() => this.$emit("getData"))
        .finally(() => (this.$store.state.isLoading = false));
    },
    doneTask(id) {
      this.$store.state.isLoading = true;
      axiosPro
        .post("/done-task", { id })
        .then(() => this.$emit("getData"))
        .finally(() => (this.$store.state.isLoading = false));
    },
  },
};
</script>
<style scoped>
/*.v-card .lighter .v-expansion-panel {
  background-color: #030e4a !important;
}*/
</style>
