import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify/lib";
import Notifications from 'vue-notification'
// import Echo from "laravel-echo";
// import Pusher from "pusher-js";
// window.Pusher = Pusher;
// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
//   wsPort: 6001,
//   cluster: "mt1",
//   forceTLS: false,
//   disableStats: true,
// });
library.add(fas);
library.add(fab);
library.add(far);
Vue.use(Vuetify);
Vue.use(Notifications);
Vue.component("f-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
