import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import ru from "vuetify/lib/locale/ru";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
});
