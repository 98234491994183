<template>
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="d-flex">
      <v-icon
        v-if="$vuetify.breakpoint.smAndDown"
        color="success"
        class="me-2 w-40"
        @click="$emit('drawerOpened')"
      >
        mdi-menu
      </v-icon>
      <h3 @click="$router.push('/')" class="clickable">AST-Group</h3>
    </div>
    <div>
      <v-tooltip bottom v-if="user">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="tasksCount"
            :value="tasksCount"
            color="red"
            overlap
          >
            <v-icon
              color="yellow"
              class="mb-1"
              @click="isOpened = true"
              v-bind="attrs"
              v-on="on"
              >mdi-bell
            </v-icon>
          </v-badge>
        </template>
        <span>Задачи</span>
      </v-tooltip>
      <v-tooltip bottom v-if="user">
        <template v-slot:activator="{ on, attrs }">
          <router-link to="/profile">
            <v-icon class="mb-1 mx-1" v-bind="attrs" v-on="on"
              >mdi-account
            </v-icon>
          </router-link>
        </template>
        <span>
          {{ user.name }}
        </span>
      </v-tooltip>
      <v-tooltip bottom v-if="user">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="white"
            class="mb-1 mx-1"
            @click="$emit('logout')"
            v-bind="attrs"
            v-on="on"
            >mdi-logout
          </v-icon>
        </template>
        <span>Выйти</span>
      </v-tooltip>
    </div>
    <v-dialog v-model="isOpened" max-width="300">
      <AppTasksModal
        @get-data="getData"
        v-if="isOpened"
        @close="isOpened = false"
        :today="todayTasks"
        :future="futureTasks"
      />
    </v-dialog>
  </div>
</template>
<script>
import create from "@/axios";
import AppTasksModal from "./modals/user/AppTasksModal.vue";

export default {
  emits: ["logout", "drawerOpened"],
  props: {
    user: { required: true },
  },
  data() {
    return {
      isOpened: false,
      tasksCount: 0,
      todayTasks: [],
      futureTasks: [],
      connected: false,
      axiosPro: create(),
    };
  },
  computed: {
    route() {
      return this.$route;
    },
  },
  watch: {
    user(v) {
      if (v && !this.connected) {
        // // window.Echo.channel(`notification.${this.$props.user.id}`).listen("GetNotification", (e) => {
        // //   this.notifications.push(e.notification);
        // //   this.notificationCount++;
        // // });
        // this.connected = true;
      }
    },
    route() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.axiosPro = create();
      this.isOpened = false;
      this.axiosPro.get("get-tasks").then((r) => {
        this.todayTasks = r.data.data.todayTasks;
        this.futureTasks = r.data.data.futureTasks;
        this.tasksCount = this.todayTasks?.length + this.futureTasks?.length;
      });
    },
  },
  mounted() {
    this.getData();
    if (this.$props.user.id && !this.connected) {
      // window.Echo.channel(`notification.${this.$props.user.id}`).listen(
      //   "GetNotification",
      //   (e) => {
      //     this.notifications.push(e.notification);
      //     this.notificationCount++;
      //   }
      // );
      // this.connected = true;
    }
  },
  components: {
    AppTasksModal,
  },
};
</script>
<style scoped>
.w-40 {
  width: 40px;
}

@media screen and (max-width: 550px) {
  .shown {
    display: none;
  }
}

@media screen and (min-width: 750px) {
  .menuShown {
    display: none;
  }
}
</style>
