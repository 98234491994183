<template>
  <v-app class="back-color">
    <v-app-bar app class="bar navbar-color h-64" color="blue lighten-3">
      <AppHeader
        :user="user"
        @logout="logout()"
        @drawerOpened="drawer = true"
      />
    </v-app-bar>
    <v-navigation-drawer
      color="blue lighten-4"
      app
      v-model="drawer"
      fixed
      temporary
      v-if="user && $vuetify.breakpoint.smAndDown"
      class="sidebar-color"
    >
      <AppSideBar
        :user="user"
        :role="user.roles[0]"
        :permissions="user.roles[0].permissions"
        :notMinimize="true"
      />
    </v-navigation-drawer>
    <v-navigation-drawer
      color="blue lighten-4"
      app
      permanent
      v-if="user && $vuetify.breakpoint.mdAndUp"
      class="nav sidebar-color"
      :mini-variant.sync="mini"
    >
      <AppSideBar
        :user="user"
        :role="user.roles[0]"
        :permissions="user.roles[0].permissions"
        @minimize="mini = true"
      />
    </v-navigation-drawer>
    <v-main class="light-blue lighten-5 position-relative">
      <v-sheet
        v-if="user"
        height="64"
        class="px-2 d-flex align-items-center sheet-color elevation-4"
        color="light-blue lighten-4"
      >
        <h3>
          {{ $route.meta.title }}
        </h3>
      </v-sheet>
      <AppLoader
        v-if="isLoading"
        class="position-absolute"
        style="top: 0; bottom: 0; right: 0; left: 0"
      />
      <v-sheet
        color="light-blue lighten-5"
        :style="{
          height: !!user ? 'calc(100% - 64px) !important' : '100% !important',
        }"
      >
        <div :class="{ hidden: isLoading, authed: !user }">
          <router-view class="p-3"></router-view>
        </div>
      </v-sheet>
    </v-main>
  </v-app>
</template>
<script>
import AppHeader from "@/components/AppHeader.vue";
import AppSideBar from "@/components/AppSideBar.vue";
import AppLoader from "@/components/AppLoader.vue";

export default {
  data() {
    return {
      mini: false,
      user: JSON.parse(localStorage.getItem("user")),
      drawer: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    route() {
      return this.$route;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
  watch: {
    route() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
  },
  components: {
    AppHeader,
    AppSideBar,
    AppLoader,
  },
};
</script>
<style>
.v-navigation-drawer__content {
  width: 100%;
}

span.nav-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-list-item > a,
.v-list-item > div {
  display: flex;
  align-items: center;
}
</style>
<style scoped>
.bar {
  left: 0 !important;
  right: 0 !important;
}

.h-64 {
  height: 64px !important;
}

.nav {
  top: 64px !important;
  bottom: 0 !important;
}

.v-application .hidden {
  display: none !important;
}

.authed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
