import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: { notAuth: true },
  },
  {
    path: "/",
    name: "main",
    component: () => import("../views/MainView.vue"),
    meta: { notAuth: false, title: "Главная" },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/user/ProfileView.vue"),
    meta: { notAuth: false, title: "Профиль" },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/user/UsersView.vue"),
    meta: { notAuth: false, title: "Список пользователей" },
  },
  {
    path: "/roles",
    name: "roles",
    component: () => import("../views/user/RolesView.vue"),
    meta: { notAuth: false, title: "Список должностей" },
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../views/client/ClientsView.vue"),
    meta: { notAuth: false, title: "Список клиентов" },
  },
  {
    path: "/client/:id",
    name: "client",
    component: () => import("../views/client/ClientView.vue"),
    meta: { notAuth: false, title: "Информация о клиенте" },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/utils/CalendarView.vue"),
    meta: { notAuth: false, title: "Календарь событий" },
  },
  {
    path: "/fit-graph",
    name: "fit-graph",
    component: () => import("../views/utils/FitGraphView.vue"),
    meta: { notAuth: false, title: "График монтажей" },
  },
  {
    path: "/mailing",
    name: "mailing",
    component: () => import("../views/utils/MailingView.vue"),
    meta: { notAuth: false, title: "Рассылка" },
  },
  {
    path: "/price-list",
    name: "price-list",
    component: () => import("../views/storage/PriceListView.vue"),
    meta: { notAuth: false, title: "Прайс-лист" },
  },
  {
    path: "/material-arrival",
    name: "material-arrival",
    component: () => import("../views/storage/MaterialArrivalView.vue"),
    meta: { notAuth: false, title: "Покупка материала" },
  },
  {
    path: "/material-sold",
    name: "material-sold",
    component: () => import("../views/storage/MaterialSoldView.vue"),
    meta: { notAuth: false, title: "Продажа материала" },
  },
  {
    path: "/summary",
    name: "material-summary",
    component: () => import("../views/storage/MaterialSummaryView.vue"),
    meta: { notAuth: false, title: "Сводка о материалов" },
  },
  {
    path: "/book-keeping",
    name: "book-keeping",
    component: () => import("../views/BookKeepingView.vue"),
    meta: { notAuth: false, title: "Бухгалтерия" },
  },
  {
    path: "/salary",
    name: "salary",
    component: () => import("../views/user/SalaryView.vue"),
    meta: { notAuth: false, title: "Зарплаты" },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/order/OrdersView.vue"),
    meta: { notAuth: false, title: "Список заявок" },
  },
  {
    path: "/order/:id",
    name: "order",
    component: () => import("../views/order/OrderView.vue"),
    meta: { notAuth: false, title: "Информация о заявке" },
  },
  {
    path: "/client-info",
    name: "client-info",
    component: () => import("../views/client/ClientInfo.vue"),
    meta: { notAuth: true, title: "Информация для клиента" },
  },
  {
    path: "/fitter-chat/:id",
    name: "fitter-chat",
    component: () => import("../views/FitterChat.vue"),
    meta: { notAuth: true, title: "Доступ к телеграм боту" },
  },
  {
    path: "/add-order",
    name: "add-order",
    component: () => import("../views/order/AddOrderView.vue"),
    meta: { notAuth: false, title: "Добавления заявки", keepAlive: true },
  },
  {
    path: "/add-task",
    name: "add-task",
    component: () => import("../views/utils/AddTaskView.vue"),
    meta: { notAuth: false, title: "Назначения задачи", keepAlive: true },
  },
  {
    path: "/today-calls",
    name: "today-calls",
    component: () => import("../views/calls/TodayCallsView.vue"),
    meta: { notAuth: false, title: "Сегодняшнее звонки" },
  },
  {
    path: "/calls-history",
    name: "calls-history",
    component: () => import("../views/calls/CallsHistoryView.vue"),
    meta: { notAuth: false, title: "История звонков" },
  },
  {
    path: "/calls-statistics",
    name: "calls-statistics",
    component: () => import("../views/calls/CallsStatisticsView.vue"),
    meta: { notAuth: false, title: "Статистика звонков" },
  },
];
const router = new VueRouter({
  routes,

  mode: "history",
  linkExactActiveClass: "active-link",
});
router.beforeEach((to, _, next) => {
  if (!to.meta.notAuth) {
    if (localStorage.getItem("user")) {
      next();
    } else {
      next("/login");
    }
  } else {
    if (localStorage.getItem("user")) {
      next("/");
    }
    next();
  }
});
export default router;
